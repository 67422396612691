import './App.css';
import { FaTwitter, FaTelegram } from "react-icons/fa"

function App() {
  return (
    <div className="App">
      <div className="wrap flex flex-col items-center justify-around min-h-screen bg-[#FFED33] px-3">
        <img src="/gvo 2.jpeg" alt="GVO" className='w-[450px]' />
        <div className="wrap text-3xl lg:text-5xl font-extrabold text-center text-knc">
          <span>$GVO COIN__</span> <br />
          <div className="mt-3">
            <span>We Dont Know the <br /> FUCKKKKK we're DOing </span>
          </div>
        </div>
        <div className="social flex gap-4">
          <a href="https://x.com/GVOcoin?t=MIGoZy-GM9kGP4O8jy7gVQ&s=09" target='_blank' className='rounded-full border-[1px] border-black p-3 text-4xl'>
            <FaTwitter />
          </a>
          <a href="https://t.me/+8Pnu_YI5-vUzODlk" target='_blank' className='rounded-full border-[1px] border-black p-3 text-4xl'>
            <FaTelegram />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
